import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { format } from "date-fns/esm"
import SanityImage from "gatsby-plugin-sanity-image"
import imageProptypes from "lib/imageProptypes"
import theme from "theme"

/**
 * Renders a grid of blog posts.
 *
 * @param {object} props
 * @param {array} props.posts Array of blog posts to display
 * @param {boolean} props.singleRow If enabled, posts will be shown in a single, scrolling row on mobile.
 */
const BlogPostGrid = ({ posts, cta, singleRow, className }) => (
  <>
    <div
      css={{
        padding: "0 30px",
        [theme.mobile]: singleRow
          ? {
              padding: 0,
              paddingLeft: "calc((100vw - min(calc(100vw - 80px), 450px)) / 2)",
              overflowX: "auto",
              width: "100%",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              userSelect: "none",
              "::-webkit-scrollbar": {
                display: "none",
                width: 0,
                height: 0,
                background: "transparent",
              },
            }
          : { padding: "0 20px" },
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: `repeat(${Math.min(3, posts.length)}, 1fr)`,
          alignItems: "stretch",
          gap: 30,
          margin: "0 auto",
          maxWidth: Math.min(1200, posts.length * 400) + 60,
          [theme.mobile]: singleRow
            ? {
                display: "flex",
                gap: 20,
                ":after": {
                  content: "''",
                  display: "block",
                  flex: "0 0 calc((100vw - min(calc(100vw - 80px), 450px) - 40px) / 2)",
                },
              }
            : {
                gap: 20,
              },
          [theme.max(850)]: !singleRow && {
            gridTemplateColumns: `repeat(${Math.min(2, posts.length)}, 1fr)`,
            "> :nth-of-type(n+3)": singleRow && { display: "none" },
          },
          [theme.max(600)]: !singleRow && {
            gridTemplateColumns: "minmax(auto, 450px)",
            justifyContent: "center",
            "> :nth-of-type(n+2)": singleRow && { display: "none" },
          },
        }}
        className={singleRow ? undefined : className}
      >
        {posts.map(post => (
          <PostCard key={post.slug.current} {...post} />
        ))}
      </div>
    </div>

    {cta && (
      <div
        css={{
          marginTop: 60,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {cta}
      </div>
    )}
  </>
)

export default BlogPostGrid

BlogPostGrid.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  cta: PropTypes.element,
  singleRow: PropTypes.bool,
  className: PropTypes.string,
}

const PostCard = ({ date, title, slug, cardImage }) => (
  <Link
    to={slug.current}
    aria-label={title}
    css={{
      flex: "0 0 min(calc(100vw - 80px), 450px)",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <SanityImage
      {...cardImage}
      width={380}
      height={290}
      alt=""
      sizes="min(380px, calc(100vw - 60px))"
      css={{
        flex: "0 0 auto",
        display: "block",
        width: "100%",
        height: "auto",
      }}
      options={{ __experimentalAspectRatio: true }}
    />

    <div
      css={{
        flex: "1 1 100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: theme.white,
        padding: 25,
      }}
    >
      <div>
        <time dateTime={date} css={{ ...theme.h5, display: "block" }}>
          {format(Date.parse(date), "MMMM dd, yyyy")}
        </time>
        <h3 css={{ ...theme.h3, margin: "1.1em 0 2em" }}>{title}</h3>
      </div>

      <div css={theme.h5}>Read More →</div>
    </div>
  </Link>
)

PostCard.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.shape({
    current: PropTypes.string.isRequired,
  }).isRequired,
  cardImage: imageProptypes.isRequired,
}
